<template>
  <div class="add-update-psychological-test">
    <el-card>
      <div slot="header">
        <h4>Psikolojik Test {{ form.ID ? 'Güncelle' : 'Ekle' }}</h4>
      </div>
      
      <!-- Test Temel Bilgileri -->
      <el-row :gutter="20" class="form-section">
        <el-col :span="12">
          <div class="form-group">
            <label>Test Başlığı</label>
            <el-input v-model="form.Title" placeholder="Test başlığını giriniz"></el-input>
          </div>
          <div class="form-group">
            <label>Test Açıklaması</label>
            <el-input type="textarea" v-model="form.Content" :rows="3" placeholder="Test açıklamasını giriniz"></el-input>
          </div>
          <div class="form-group">
            <label>Tahmini Süre (dk)</label>
            <el-input-number v-model="form.Duration" :min="0" controls-position="right"></el-input-number>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="form-group">
            <label>Test Görseli</label>
            <div class="image-upload-container" >
              <input type="file" @change="uploadFile($event)" style="display: none" ref="file_test" />
              <div v-if="!form.Image" class="image upload-button" @click="uploadFiles()">
                <i class="el-icon-upload"></i>
                <div>Fotoğraf Yükle</div>
              </div>
              <div v-else class="image image-preview" :style="{ backgroundImage: 'url(' + form.Image + ')' }">
                <div class="image-overlay">
                  <i class="el-icon-delete remove-icon" @click.stop="removeImage()"></i>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- Sorular Bölümü -->
      <div class="section-title">
        <h5>Sorular</h5>
      </div>
      <el-collapse>
        <el-collapse-item v-for="(question, qIndex) in form.Questions" :key="qIndex" :title="`Soru ${qIndex + 1}`">
          <div class="header-with-button">
            <h6>Soru {{ qIndex + 1 }}</h6>
            <el-button type="danger" size="mini" @click="removeQuestion(qIndex)" icon="el-icon-delete">Sil</el-button>
          </div>
          
          <div class="form-group">
            <label>Soru Metni</label>
            <el-input v-model="question.Title" placeholder="Soru metni"></el-input>
          </div>
          
          <!-- Cevaplar -->
          <div class="answers-section">
            <h6>Cevaplar</h6>
            <div v-for="(answer, aIndex) in question.Answers" :key="aIndex" class="answer-row">
              <el-row :gutter="10">
                <el-col :span="18">
                  <el-input v-model="answer.Option" placeholder="Cevap seçeneği"></el-input>
                </el-col>
                <el-col :span="4">
                  <el-input-number v-model="answer.Point" :min="0" controls-position="right" placeholder="Puan"></el-input-number>
                </el-col>
                <el-col :span="2">
                  <el-button 
                    type="danger" 
                    size="mini" 
                    icon="el-icon-delete" 
                    @click="removeAnswer(qIndex, aIndex)" 
                    :disabled="question.Answers.length <= 1">
                  </el-button>
                </el-col>
              </el-row>
            </div>
            <el-button 
              type="primary" 
              size="small" 
              icon="el-icon-plus" 
              @click="addAnswer(qIndex)"
              :disabled="question.Answers.length >= 4"
              class="add-answer-btn">
              Cevap Ekle ({{ question.Answers.length }}/4)
            </el-button>
          </div>
        </el-collapse-item>
      </el-collapse>
      
      <el-button type="primary" plain class="add-btn" @click="addQuestion" icon="el-icon-plus">Yeni Soru Ekle</el-button>

      <!-- Sonuç Aralıkları -->
      <div class="section-title">
        <h5>Sonuç Önerileri</h5>
      </div>
      <el-collapse>
        <el-collapse-item v-for="(result, rIndex) in form.Results" :key="rIndex" :title="`Sonuç ${rIndex + 1}: ${result.Title || 'Yeni sonuç'}`">
          <div class="header-with-button">
            <h6>Sonuç {{ rIndex + 1 }}</h6>
            <el-button 
              type="danger" 
              size="mini" 
              icon="el-icon-delete" 
              @click="removeResult(rIndex)"
              :disabled="form.Results.length <= 1">
              Sil
            </el-button>
          </div>
          
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="form-group">
                <label>Başlık</label>
                <el-input v-model="result.Title" placeholder="Sonuç başlığı"></el-input>
              </div>
              <div class="form-group">
                <label>Açıklama</label>
                <el-input type="textarea" v-model="result.Description" :rows="3" placeholder="Sonuç açıklaması"></el-input>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="form-group">
                <label>Puan Aralığı</label>
                <div class="range-inputs">
                  <el-input-number v-model="result.Min" :min="0" placeholder="Min puan" controls-position="right"></el-input-number>
                  <el-input-number v-model="result.Max" :min="0" placeholder="Max puan" controls-position="right" style="margin-left: 10px;"></el-input-number>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>
      
      <el-button type="primary" plain class="add-btn" @click="addResult" icon="el-icon-plus">Yeni Sonuç Ekle</el-button>

      <!-- Kaydet Butonu -->
      <div class="action-buttons">
        <el-button @click="$router.back()">İptal</el-button>
        <el-button type="success" @click="saveTest">Kaydet</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "AddUpdatePsychologicalTest",
  data() {
    return {
      form: {
        ID: null,
        Title: null,
        Content: null,
        OrderNo: 0,
        Image: null,
        ParticipationCount: "",
        Questions: [],
        Results: [
          {
            Title: null,
            Description: null,
            Min: 0,
            Max: 0,
          },
        ],
        Slug: null,
        CategoryIds: [],
        Duration: 0,
      },
    };
  },
  methods: {
    addQuestion() {
      this.form.Questions.push({
        OrderNo: this.form.Questions.length,
        Title: null,
        Answers: [
          {
            OrderNo: 0,
            Option: null,
            Point: 0,
          }
        ],
        QuestionAdditionalInformation: null,
        QuestionAdditionalInformationImg: null,
      });
    },
    removeQuestion(index) {
      this.form.Questions.splice(index, 1);
      // Sıra numaralarını güncelle
      this.form.Questions.forEach((q, idx) => {
        q.OrderNo = idx;
      });
    },
    addAnswer(questionIndex) {
      if (this.form.Questions[questionIndex].Answers.length < 4) {
        this.form.Questions[questionIndex].Answers.push({
          OrderNo: this.form.Questions[questionIndex].Answers.length,
          Option: null,
          Point: 0,
        });
      }
    },
    removeAnswer(questionIndex, answerIndex) {
      if (this.form.Questions[questionIndex].Answers.length > 1) {
        this.form.Questions[questionIndex].Answers.splice(answerIndex, 1);
        // Sıra numaralarını güncelle
        this.form.Questions[questionIndex].Answers.forEach((a, idx) => {
          a.OrderNo = idx;
        });
      }
    },
    addResult() {
      this.form.Results.push({
        Title: null,
        Description: null,
        Min: 0,
        Max: 0,
      });
    },
    removeResult(index) {
      if (this.form.Results.length > 1) {
        this.form.Results.splice(index, 1);
      }
    },
    async uploadFile(event) {
      let file = event.target.files[0];
      var fd = new FormData();
      fd.append("file", file);
      
      var res = await this.$client.post("/Account/UploadFile?type=3&RelatedItemId=" + (this.form.ID || 0), fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      if (res.data.HasError != undefined && res.data.HasError == true) {
        this.$message.error(res.data.Message);
      } else {
        this.form.Image = res.data.Item.Url;
      }
      
      this.$refs.file_test.value = null;
    },
    
    uploadFiles() {
      this.$refs.file_test.click();
    },
    
    removeImage() {
      this.form.Image = null;
    },
    
    async saveTest() {
      // Form doğrulama
      if (!this.form.Title) {
        this.$message.error('Test başlığı zorunludur');
        return;
      }
      
      if (this.form.Questions.length === 0) {
        this.$message.error('En az bir soru eklemelisiniz');
        return;
      }
      if (this.form.Results.length === 0) {
        this.$message.error('En az bir sonuç eklemelisiniz');
        return;
      }
      // Burada API'ye form bilgilerini gönderen bir metod olacak
      console.log("Test kaydediliyor:", this.form);
      var res = await this.$store.dispatch("addTest", this.form);
      if(!res.HasError){
        this.$router.push('/admin/psikolojik-testler');
      }
    }
  },
    async created() {
    // Başlangıçta en az bir soru olsun
    if(this.$route.params.id){
     var res = await this.$store.dispatch("getTest", {Id: this.$route.params.id});
     if(res.HasError){
      this.$message.error(res.Message);
      this.$router.push('/admin/psikolojik-testler');
     }else{
      this.form = res.Data;
     }
    }
    if (this.form.Questions.length === 0) {
      this.addQuestion();
    }
  }
};
</script>

<style scoped>
.add-update-psychological-test {
  padding: 20px;
}

.form-section {
  margin-bottom: 20px;
}

.section-title {
  margin: 25px 0 15px 0;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.header-with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.answers-section {
  margin-top: 15px;
}

.answer-row {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.add-answer-btn {
  margin-top: 10px;
}

.add-btn {
  margin-top: 15px;
}

.range-inputs {
  display: flex;
  align-items: center;
}

.image-upload-container {
  display: flex;
  flex-direction: column;
}

.image {
  cursor: pointer;
  width: 250px;
  height: 250px;
  background-position: center;
  background-size: cover !important;
  background: #f0f2f5;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.upload-button {
  opacity: 0.8;
  background-color: #f0f2f5;
  border: 1px dashed #ccc;
}

.upload-button div, .upload-button i {
  color: #888;
  font-weight: 500;
  font-size: 15px;
  align-self: center;
  text-align: center;
}

.image-preview {
  opacity: 1;
}

.image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  display: none;
}

.image-preview:hover .image-overlay {
  display: block;
  background: rgba(0,0,0,0.15);
  border-top-right-radius: 8px;
}

.remove-icon {
  color: #ff4d4f;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}

.remove-icon:hover {
  transform: scale(1.1);
  color: #ff7875;
}

.action-buttons {
  margin-top: 30px;
  text-align: right;
}
</style>
